<template>
<div>
  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="banks"
      :search="search"
      sort-by="nombre"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.dir`]="{ item }">
          {{ item.desdepp + ', ' + item.desmunp + ', ' + item.desbop }}
      </template>
      <template v-slot:[`item.location`]="{ item }">
          {{ item.desdepf + ', ' + item.desmunf + ', ' + item.desbof }}
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
         
          <v-dialog v-model="dialog" persistent max-width="1000px">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title class="pt-0 pb-0">
                <span class="headline">{{ formTitle }}</span>
                <v-spacer></v-spacer>
               <v-radio-group
                 row
               >
                 <v-switch
                    v-model="editedItem.partner"
                    inset
                    label="Intermediario"
                    class="mr-4"
                  ></v-switch>

               </v-radio-group>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- Poner mascara -->

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.rtn"
                          v-mask="'####-####-######'"
                          label="RTN"
                          :rules="editedItem.rtnRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombres"
                          :rules="editedItem.nameRules"
                          autocomplete="new-name"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.codihc"
                          label="Clave IHCAFE"
                          :rules="editedItem.codihcRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.phone"
                          v-mask="'####-####'"
                          label="Teléfono"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-card elevation="3">
                          <v-card-title>
                            <v-icon class="mr-2" color="blue">fas fa-address-card</v-icon> 
                            Dirección del productor
                          </v-card-title>
  
                          <v-card-text>
                            
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.coddepp"
                                :items="departaments"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge un Departamento"
                                :rules="editedItem.coddeppRules"
                                @change="getMunicipios"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.codmunp"
                                :items="municipios"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge un Municipio"
                                :rules="editedItem.codmunpRules"
                                @change="getBarrios"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.codbop"
                                :items="barrios"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge una Aldea"
                                :rules="editedItem.codbopRules"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
  
                          </v-card-text>
                        </v-card>
                      </v-col>


                      <v-col cols="12" sm="12" md="6">
                        <v-card elevation="3">
                          <v-card-title>
                            <v-icon class="mr-2" color="red">fas fa-map-marker-alt</v-icon> 
                            Ubicación de la finca
                          </v-card-title>
  
                          <v-card-text>
                            
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.coddepf"
                                :items="departaments"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge un Departamento"
                                :rules="editedItem.coddepfRules"
                                @change="getMunicipiosFarm"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.codmunf"
                                :items="municipiosFarm"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge un Municipio"
                                :rules="editedItem.codmunfRules"
                                @change="getBarriosFarm"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-autocomplete
                                v-model="editedItem.codbof"
                                :items="barriosFarm"
                                item-text="descripcio"
                                item-value="codigo"
                                label="Escoge una Aldea"
                                :rules="editedItem.codbofRules"
                                clearable
                                rounded
                                solo
                                return-object
                              ></v-autocomplete>
                            </v-col>
  
                          </v-card-text>
                        </v-card>
                      </v-col>


                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>Desea eliminar a {{editedItem.name}}?</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-actions>
                <v-btn color="green darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="red" text @click="eliminar">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="success" small class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" small @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  name: "providers",
  data: () => ({
    dialog: false,
    banks: [],
    departaments: [],
    municipios: [],
    barrios: [],
    municipiosFarm: [],
    barriosFarm: [],
    dialogDelete: false,
    search: "",
    loading: "",
    titulo: "Productores",
    headers: [
      { text: "RTN", align: "left", value: "rtn" },
      { text: "Nombre", align: "left", value: "name" },
      { text: "Teléfono", align: "left", value: "telprin" },
      { text: "Clave", align: "left", value: "codihc" },
      { text: "Dirección Productor", align: "left", value: "dir" },
      { text: "Ubicación Finca", align: "left", value: "location" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    editedItem: {
      id: "",
      rtn: "",
      name: "",
      phone: "",
      codihc: "",
      partner: false,

      coddepp: null,
      codmunp: null,
      codbop: null,
      desdepp: "",
      desmunp: "",
      desbop: "",

      coddepf: null,
      codmunf: null,
      codbof: null,
      desdepf: "",
      desmunf: "",
      desbof: "",

      nameRules: [v => !!v || "Escribe el nombre"],
      codihcRules: [v => !!v || "Escribe la clave"],
      coddeppRules: [v => !!v || "Escoge el Departamento"],
      codmunpRules: [v => !!v || "Escoge el Municipio"],
      codbopRules: [v => !!v || "Escoge la Aldea"],

      coddepfRules: [v => !!v || "Escoge el Departamento"],
      codmunfRules: [v => !!v || "Escoge el Municipio"],
      codbofRules: [v => !!v || "Escoge la Aldea"],

      rtnRules: [
                      v => !!v || "Escribe el RTN",
                      v => /^\d{4}-\d{4}-\d{6}$/.test(v) || "Escribe correctamente el RTN"
                ],
      phoneRules: [
                    v => /^(2|3|9|8)\d{3}-\d{4}$/.test(v) || "El teléfono debe ser valido"
                  ],
      active: false
    },
    defaultItem: {
      id: "",
      rtn: "",
      name: "",
      phone: "",
      codihc: "",
      partner: false,

      coddepp: null,
      codmunp: null,
      codbop: null,
      desdepp: "",
      desmunp: "",
      desbop: "",

      coddepf: null,
      codmunf: null,
      codbof: null,
      desdepf: "",
      desmunf: "",
      desbof: "",

      nameRules: [v => !!v || "Escribe el nombre"],
      codihcRules: [v => !!v || "Escribe la clave"],
      coddeppRules: [v => !!v || "Escoge el Departamento"],
      codmunpRules: [v => !!v || "Escoge el Municipio"],
      codbopRules: [v => !!v || "Escoge la Aldea"],

      coddepfRules: [v => !!v || "Escoge el Departamento"],
      codmunfRules: [v => !!v || "Escoge el Municipio"],
      codbofRules: [v => !!v || "Escoge la Aldea"],

      rtnRules: [
                      v => !!v || "Escribe el RTN",
                      v => /^\d{4}-\d{4}-\d{6}$/.test(v) || "Escribe correctamente el RTN"
                ],
      phoneRules: [
                    v => !!v || "Escribe el teléfono",
                    v => /^(2|3|9|8)\d{3}-\d{4}$/.test(v) || "El teléfono debe ser valido"
                  ],
      active: false
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getBanks(){
      await axios.get(this.db + 'retention/providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    addProcess(payload){
      console.log(payload);
      axios.post(this.db + 'retention/provider/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.banks.push(this.editedItem);
        this.getBanks();
        this.close();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.addProcess(payload); })
      });

    },
    updateProcess(payload){
      axios.post(this.db + 'retention/provider/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getBanks();
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.updateProcess(payload); })
      });

    },

    deleteProcess(id){

      axios.post(this.db + 'retention/provider/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.banks = this.banks.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        console.log(error);
        this.validateSession(error).then((resp) => { if(resp) this.deleteProcess(id); })
      });

    },

    async getDepartaments(){
      await axios.get(this.db + 'departaments',
        {
          headers: this.headers_db
        }).then(response => {
          this.departaments = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getDepartaments(); })
        });
    },

    async getMunicipios(){
      this.municipios = [];
      this.barrios = [];
      this.editedItem.codmunp = null;

      if (this.editedItem.coddepp == null) {
        return;
      }
      
      await axios.get(this.db + 'municipios/' + this.editedItem.coddepp.codigo,
        {
          headers: this.headers_db
        }).then(response => {
          this.municipios = response.data.data;
          this.loading = false;
          // console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getMunicipios(); })
        });
    },

    async getBarrios(){
      this.barrios = [];
      this.editedItem.codbop = null;
      
      if (this.editedItem.codmunp == null) {
        return;
      }
      
      await axios.get(this.db + 'barrios/' + this.editedItem.coddepp.codigo + '/' + this.editedItem.codmunp.codigo,
        {
          headers: this.headers_db
        }).then(response => {
          this.barrios = response.data.data;
          this.loading = false;
          // console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBarrios(); })
        });
    },

    async getMunicipiosFarm(){
      this.municipiosFarm = [];
      this.barriosFarm = [];
      this.editedItem.codmunf = null;
      
      if (this.editedItem.coddepf == null) {
        return;
      }
      
      await axios.get(this.db + 'municipios/' + this.editedItem.coddepf.codigo,
        {
          headers: this.headers_db
        }).then(response => {
          this.municipiosFarm = response.data.data;
          this.loading = false;
          // console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getMunicipios(); })
        });
    },

    async getBarriosFarm(){
      this.barriosFarm = [];
      this.editedItem.codbof = null;
      
      if (this.editedItem.codmunf == null) {
        return;
      }

      let coddepf = this.editedItem.coddepf.codigo ? this.editedItem.coddepf.codigo : this.editedItem.coddepf;
      let codmunf = this.editedItem.codmunf.codigo ? this.editedItem.codmunf.codigo : this.editedItem.codmunf;
      
      await axios.get(this.db + 'barrios/' + coddepf + '/' + codmunf,
        {
          headers: this.headers_db
        }).then(response => {
          this.barriosFarm = response.data.data;
          this.loading = false;
          // console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getBarrios(); })
        });
    },
    // FIN Metodos base de datos


    // Metodos Generales

    async editItem(item) {
      console.log(item);
      this.editedItem.coddepp = {codigo: item.coddepp};
      await this.getMunicipios();
      this.editedItem.codmunp = {codigo: item.codmunp};
      await this.getBarrios();

      this.editedItem.coddepf = {codigo: item.coddepf};
      await this.getMunicipiosFarm();
      this.editedItem.codmunf = {codigo: item.codmunf};
      await this.getBarriosFarm();
      
      item.partner = item.partner == 1 ? true : false;
      this.editedIndex = this.banks.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.form.validate()) {
          this.editedItem.desdepf = this.editedItem.coddepf.descripcio ? this.editedItem.coddepf.descripcio : this.editedItem.desdepf;
          this.editedItem.desmunf = this.editedItem.codmunf.descripcio ? this.editedItem.codmunf.descripcio : this.editedItem.desmunf;
          this.editedItem.desbof = this.editedItem.codbof.descripcio ? this.editedItem.codbof.descripcio : this.editedItem.desbof;
          this.editedItem.desdepp = this.editedItem.coddepp.descripcio ? this.editedItem.coddepp.descripcio : this.editedItem.desdepp;
          this.editedItem.desmunp = this.editedItem.codmunp.descripcio ? this.editedItem.codmunp.descripcio : this.editedItem.desmunp;
          this.editedItem.desbop = this.editedItem.codbop.descripcio ? this.editedItem.codbop.descripcio : this.editedItem.desbop;

          this.editedItem.coddepp = this.editedItem.coddepp.codigo ? this.editedItem.coddepp.codigo : this.editedItem.coddepp;
          this.editedItem.codmunp = this.editedItem.codmunp.codigo ? this.editedItem.codmunp.codigo : this.editedItem.codmunp;
          this.editedItem.codbop = this.editedItem.codbop.codigo ? this.editedItem.codbop.codigo : this.editedItem.codbop;

          this.editedItem.coddepf = this.editedItem.coddepf.codigo ? this.editedItem.coddepf.codigo : this.editedItem.coddepf;
          this.editedItem.codmunf = this.editedItem.codmunf.codigo ? this.editedItem.codmunf.codigo : this.editedItem.codmunf;
          this.editedItem.codbof = this.editedItem.codbof.codigo ? this.editedItem.codbof.codigo : this.editedItem.codbof;
          this.editedItem.partner = this.editedItem.partner ? 1 : 0;

        if (this.editedIndex > -1) {
          Object.assign(this.banks[this.editedIndex], this.editedItem);
          console.log("editar",this.editedItem);
          this.updateProcess(this.editedItem);
          this.close();
        } else {
          this.addProcess(this.editedItem);
        }
        
      }
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },
  },
  created() {
    this.getBanks();
    this.getDepartaments();
  }
};
</script>

